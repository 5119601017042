import { FC, ReactNode } from 'react'

import './strikethrough.scss'

interface IStrikeThroughProperties {
    children: ReactNode
}
const StrikeThrough: FC<IStrikeThroughProperties> = ({ children }) => <div className='u-strike-through'>{children}</div>

export default StrikeThrough
