import { useContext, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import { KioskContext } from 'src/context/KioskContextProvider'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

export const useScreenHeight = () => {
    const screenHeight = use100vh()
    const { kioskMode } = useContext(KioskContext)
    useEffect(() => {
        if (isNotNullOrUndefined(screenHeight)) {
            document.documentElement.style.setProperty('--screen-height', `${screenHeight * (kioskMode ? 0.65 : 1)}px`)
        }
    }, [screenHeight])
    return use100vh()
}
