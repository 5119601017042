import { useQuery } from '@tanstack/react-query'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IVenue from 'src/structures/Interfaces/IVenue'

import { getQuerystringParameterByName } from '../constants/helpers'
import BillySDK from '../sdk/sdk'

const useVenueQuery = (venueId: string | undefined, parameters = null) => {
    const tags = getQuerystringParameterByName('tags')

    const {
        isLoading,
        error,
        isError,
        data: venue,
    } = useQuery({
        retry: 2,
        refetchOnWindowFocus: false,
        refetchInterval: 60 * 3 * 1000,
        queryKey: ['venue', { venueId }],
        enabled: isNotNullOrUndefined(venueId),
        select: (response) => response.data as IVenue,
        queryFn: async () =>
            BillySDK.getVenueById(venueId, {
                ...(isNotNullOrUndefined(tags) ? { tags } : {}),
                ...(isNotNullOrUndefined(parameters) ? { parameters } : {}),
            }),
    })

    return {
        isLoading,
        error,
        isError,
        venue,
    }
}

export default useVenueQuery
