import AuthenticationModal from '../../components/03_organisms/Modals/AuthenticationModal/AuthenticationModal.tsx'
import CrossSellModal from '../../components/03_organisms/Modals/CrossSellModal/CrossSellModal.tsx'
import ExternalContentModal from '../../components/03_organisms/Modals/ExternalContentModal/ExternalContentModal.tsx'
import LoyaltyRegistrationModal from '../../components/03_organisms/Modals/LoyaltyRegistrationModal/LoyaltyRegistrationModal.tsx'
import LoyaltyRewardsModal from '../../components/03_organisms/Modals/LoyaltyRewardsModal/LoyaltyRewardsModal.tsx'
import ProductConfiguratorModal from '../../components/03_organisms/Modals/ProductConfiguratorModal/ProductConfiguratorModal.tsx'
import ProductModal from '../../components/03_organisms/Modals/ProductModal/ProductModal.tsx'
import ProductQuantityModal from '../../components/03_organisms/Modals/ProductQuantityModal/ProductQuantityModal.tsx'
import PromotionModal from '../../components/03_organisms/Modals/PromotionModal/PromotionModal.tsx'
import QueryParameterModals from '../../components/03_organisms/Modals/QueryParameterModals/QueryParameterModals.tsx'
import UpsellModal from '../../components/03_organisms/Modals/UpsellModal/UpsellModal.tsx'
import ModalType from '../../structures/Enums/ModalType.ts'

import ApplyLoyaltyModal from './components/ApplyLoyaltyModal'
import ApplyReductionModal from './components/ApplyReductionModal'
import CompetitionModal from './components/CompetitionModal'
import ConfimModal from './components/ConfimModal'
import CustomTippingModal from './components/CustomTippingModal'
import DialogModal from './components/DialogModal'
import ErrorModal from './components/ErrorModal'
import InactivityModal from './components/InactivityModal'
import PayByQrCodeModal from './components/PayByQrCodeModal'
import RatingModal from './components/RatingModal'
import StarnetPaymentModal from './components/StarnetPaymentModal'
import VideoStoryModal from './components/VideoStoryModal'
import VoucherPaymentModal from './components/VoucherPaymentModal'
import ModalRoot from './ModalRoot'

function Modals() {
    return (
        <>
            <QueryParameterModals />

            <ModalRoot>
                <ProductModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.ADD_TO_BASKET_MODAL}
                />
                <ProductQuantityModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.QUANTITY_SELECTOR_MODAL}
                />
                <ErrorModal
                    canCloseByBackdrop
                    type={ModalType.ERROR_MODAL}
                />
                <ConfimModal
                    canCloseByBackdrop
                    type={ModalType.CONFIRM_MODAL}
                />
                <DialogModal
                    canCloseByBackdrop
                    type={ModalType.DIALOG_MODAL}
                />
                <RatingModal
                    canCloseByBackdrop
                    type={ModalType.RATING_MODAL}
                />
                <CrossSellModal
                    position='bottom'
                    type={ModalType.CROSSSELL_MODAL}
                />
                <UpsellModal
                    canCloseByBackdrop={false}
                    position='bottom'
                    type={ModalType.UPSELL_ADDITIONS_MODAL}
                />
                <ApplyLoyaltyModal
                    canCloseByBackdrop
                    type={ModalType.APPLY_LOYALTY_MODAL}
                />
                <ApplyReductionModal
                    canCloseByBackdrop
                    type={ModalType.APPLY_REDUCTION_MODAL}
                />
                <StarnetPaymentModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.STARNET_PAYMENT_MODAL}
                />
                <VoucherPaymentModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.VOUCHER_PAYMENT_MODAL}
                />
                <InactivityModal
                    canCloseByBackdrop
                    type={ModalType.INACTIVITY_MODAL}
                />
                <VideoStoryModal
                    canCloseByBackdrop
                    type={ModalType.VIDEO_STORY_MODAL}
                />
                <PayByQrCodeModal
                    canCloseByBackdrop={false}
                    type={ModalType.PAY_BY_QR_MODAL}
                />
                <ProductConfiguratorModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.PRODUCT_CONFIGURATOR_MODAL}
                />
                <PromotionModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.PROMO_MODAL}
                />
                <CustomTippingModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.CUSTOM_TIPPING_MODAL}
                />
                <CompetitionModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.COMPETITION_MODAL}
                />
                <LoyaltyRegistrationModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.LOYALTY_REGISTRATION_MODAL}
                />

                <LoyaltyRewardsModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.LOYALTY_REWARDS_MODAL}
                />

                <AuthenticationModal
                    position='bottom'
                    type={ModalType.AUTHENTICATION_MODAL}
                />

                <ExternalContentModal
                    canCloseByBackdrop
                    position='bottom'
                    type={ModalType.EXTERNAL_CONTENT_MODAL}
                />
            </ModalRoot>
        </>
    )
}

export default Modals
