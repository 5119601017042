import { FC } from 'react'
import { TbPhotoSquareRounded } from 'react-icons/tb'

import './imagefallback.scss'

type IImageFallbackProperties = Record<string, null>

const ImageFallback: FC<IImageFallbackProperties> = () => (
    <div className='image-fallback'>
        <TbPhotoSquareRounded className='image-icon' />
    </div>
)

export default ImageFallback
