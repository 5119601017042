import { FC, HTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

import './modal.scss'

interface IModalProperties extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode | null
    containerClassName?: string
    contentClassName?: string
}

const Modal: FC<IModalProperties> = ({ children, containerClassName, contentClassName, ...rest }) => (
    <section
        className={classNames('modal-content-container', containerClassName)}
        {...rest}>
        <div className={classNames('modal-content', contentClassName)}>{children}</div>
    </section>
)

export default Modal
