import { FC, ReactNode, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'src/redux/actions/actionBuilders'
import ModalType from 'src/structures/Enums/ModalType'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './externalcontentpreviewlink.scss'

interface IExternalContentPreviewLinkProperties {
    url: string
    children: ReactNode
}

const ExternalContentPreviewLink: FC<IExternalContentPreviewLinkProperties> = ({ url, children }) => {
    const dispatch = useDispatch()
    const handleClick = useCallback(() => {
        if (isNotNullOrUndefined(url)) {
            dispatch(
                openModal({
                    type: ModalType.EXTERNAL_CONTENT_MODAL,
                    data: {
                        url,
                    },
                })
            )
        }
    }, [url, dispatch])

    return (
        <button
            type='button'
            className='externalcontentpreviewlink'
            onClick={handleClick}>
            {children}
        </button>
    )
}

export default ExternalContentPreviewLink
