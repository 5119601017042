import { useContext } from 'react'
import classNames from 'classnames'
import { ErrorMessage } from 'formik'

import { KioskContext } from '../../../context/KioskContextProvider'

import InputHelp from './InputHelp'

function Input({
    field: { name, value, onChange, onBlur },
    form: { errors, touched },
    id,
    type = 'text',
    inputMode = 'text',
    label,
    placeHolder,
    className,
    maxLength,
    description,
    autoComplete,
    feedbackStyle,
    disabled = false,
    ...props
}) {
    const { kioskMode } = useContext(KioskContext)
    const classes = classNames(
        'input-text c-input--default',
        {
            'has-success': value ?? (!errors[name] && touched[name]), // handle prefilled or user-filled
            'has-error': !!errors[name] && touched[name],
            'u-disabled': disabled,
            'u-kioskmode': kioskMode,
        },
        className
    )

    return (
        <div className='form-element'>
            {label ? <label htmlFor={id}>{label}</label> : null}
            <input
                disabled={disabled}
                name={name}
                id={id}
                type={type}
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                className={classes}
                placeholder={placeHolder}
                autoComplete={autoComplete}
                autoFocus={props.autoFocus || false}
                maxLength={maxLength}
                inputMode={inputMode}
                {...props}
            />

            <ErrorMessage
                name={name}
                component='p'
                className='u-error'
            />
            {description && type !== 'hidden' ? <InputHelp content={description} /> : null}
        </div>
    )
}

export default Input
