import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Button from '../../../components/01_atoms/Button/Button.tsx'
import PoweredBy from '../../../components/PoweredBy/PoweredBy'
import { slideInBottom } from '../../../constants/Animations.ts'
import { useScreenHeight } from '../../../hooks/useScreenHeight.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'
import { BRAND_TADA } from '../../../structures/Enums/Brands.enum.ts'

import './modal.scss'

function CompetitionModal({ data = {} }) {
    const { t } = useTranslation()
    const screenHeight = useScreenHeight()
    const dispatch = useDispatch()

    const handleOnConfirm = useCallback(() => {
        data.onConfirm?.()
        dispatch(closeModal())
    }, [data, dispatch, closeModal])

    const handleOnReject = useCallback(() => {
        data.onReject?.()
        dispatch(closeModal())
    }, [data, dispatch, closeModal])

    if (data?.competition?.promotion) {
        return (
            <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
                <motion.div
                    className='c-modal--item c-modal--promo'
                    variants={slideInBottom}
                    initial='hidden'
                    animate='visible'
                    exit='exit'>
                    <div className='c-fake-modal-backdrop'>
                        <div className='c-close-modal-header'>
                            <button
                                aria-label={t('general.button.close.label')}
                                onClick={handleOnReject}
                                className='c-button-close-modal-small'>
                                <FaTimes />
                            </button>
                        </div>
                        <div
                            style={{
                                maxHeight: `${parseInt(screenHeight * 0.9, 10)}px`,
                            }}
                            className='c-modal--item-content'>
                            <div className='c-modal-promo-wrapper'>
                                <div
                                    className='c-modal-promo-image'
                                    style={{ position: 'static', background: data.competition.promotion?.color }}>
                                    {data.competition.promotion?.cover_image ? (
                                        <img src={data.competition.promotion.cover_image} />
                                    ) : null}
                                </div>
                                <div
                                    className='c-modal-promo-content'
                                    style={{ padding: '3px 20px' }}>
                                    <div className='c-modal-promo-head'>
                                        <p
                                            className='c-modal-promo-promoname'
                                            style={
                                                data.competition.promotion?.color
                                                    ? {
                                                          color: `#${String(data.competition.promotion.color).replace(
                                                              '#',
                                                              ''
                                                          )}`,
                                                      }
                                                    : null
                                            }>
                                            {data.competition.promotion?.name}
                                        </p>

                                        <PoweredBy
                                            brandId={BRAND_TADA.identifier}
                                            isStaticPositioned
                                        />
                                    </div>
                                    <Button
                                        onClick={handleOnConfirm}
                                        label={t('competition.participate.cta')}
                                        className='c-button-dark'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        )
    }
    return null
}

export default CompetitionModal
