import { useCallback, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import { matchPathsByType } from '../constants/Path.utils.js'
import { KioskContext } from '../context/KioskContextProvider'
import { ThemeContext } from '../context/ThemeContextProvider'
import RoutePath from '../structures/Enums/RoutePath.enum.js'

const useNavigationBehaviour = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { theme } = useContext(ThemeContext)
    const { kioskMode } = useContext(KioskContext)

    const previousLocation: string = location?.state?.from

    const mutatePreviousLocation = useCallback(
        (mutation: string) => {
            if (isNotNullOrUndefined(location.state)) {
                location.state.from = mutation
            } else {
                location.state = { from: mutation }
            }
        },
        [location?.state]
    )

    const onBackNavigation = useCallback(() => {
        const pathType = matchPathsByType(location.pathname)
        const venuePath = theme?.venueScreenDisabled === true ? theme.placePath ?? RoutePath.HOMEPAGE : theme.venuePath
        let pathToReturnTo = previousLocation ?? -1

        switch (pathType) {
            case 'event': {
                pathToReturnTo = kioskMode ? RoutePath.EVENTS : venuePath
                break
            }

            case 'purchase': {
                pathToReturnTo = venuePath
                break
            }

            case 'eventGroupEvent': {
                const eventGroupEventPath = location.pathname
                const eventGroupPath = eventGroupEventPath.slice(0, eventGroupEventPath.lastIndexOf('/'))
                if (matchPathsByType(eventGroupPath) === 'eventGroup') {
                    pathToReturnTo = eventGroupPath
                }
                break
            }

            case 'place':
            case 'eventGroup': {
                pathToReturnTo = RoutePath.HOMEPAGE
                break
            }

            case 'venue': {
                pathToReturnTo = theme.placePath ?? RoutePath.HOMEPAGE
                break
            }

            case 'categoryGroup': {
                pathToReturnTo = location.pathname.slice(0, location.pathname.lastIndexOf('/g'))
                break
            }

            case 'category': {
                pathToReturnTo = location.pathname.slice(0, location.pathname.lastIndexOf('/c'))
                break
            }

            default: {
                pathToReturnTo = previousLocation ?? -1
                break
            }
        }

        navigate(pathToReturnTo, {
            state: { ...location.state },
        })
    }, [matchPathsByType, location?.pathname, navigate, theme])

    return {
        previousLocation,
        onBackNavigation,
        mutatePreviousLocation,
    }
}

export default useNavigationBehaviour
