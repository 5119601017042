import {
    ComponentPropsWithoutRef,
    createElement,
    ElementType,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { closeAllModals } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import ExternalContentPreviewLink from '../ExternalContentPreviewLink/ExternalContentPreviewLink'

interface IPolymorphicAsProperty<E extends ElementType> {
    as?: E
}

type PolymorphicProperties<E extends ElementType> = PropsWithChildren<
    ComponentPropsWithoutRef<E> & IPolymorphicAsProperty<E>
>

const defaultElement = 'div'

const isExternalUrl = (url: string) => {
    try {
        const link = new URL(url, window.location.origin)
        return link.origin !== window.location.origin
    } catch {
        // Invalid URLs are considered non-external
        return false
    }
}

type HtmlRendererProperties<E extends ElementType = typeof defaultElement> = PolymorphicProperties<E> & {
    innerHTML: string | TrustedHTML
    sanitizeOptions?: DOMPurify.Config
    forceExternalLinksIntoIFrame?: boolean
}

const HtmlRenderer = <E extends ElementType = typeof defaultElement>({
    as,
    innerHTML,
    sanitizeOptions = {},
    forceExternalLinksIntoIFrame = false,
    ...rest
}: HtmlRendererProperties<E>) => {
    const dispatch = useDispatch()
    const Component = as ?? defaultElement

    // when the user clicks an internal link from a modal, we close all modals
    // Otherwise the modal would remain open when navigating to the new page (this is a bug and should be fixed)
    const handleInternalLinkClick = useCallback(() => {
        dispatch(closeAllModals())
    }, [dispatch])

    const sanitizeInnerHtml = useCallback(
        (input: string | TrustedHTML): Array<ReactNode> => {
            // Sanitize input using DOMPurify
            const sanitizedHtml = DOMPurify.sanitize(String(input), { ...sanitizeOptions })

            // Create a temporary container for sanitized HTML
            const container = document.createElement('div')
            container.innerHTML = sanitizedHtml

            // Process each child node
            const preprocessHtml = (node: ChildNode): ReactNode => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const element = node as HTMLElement
                    const tagName = element.tagName.toLowerCase()

                    // Handling for links
                    if (tagName === 'a') {
                        const href = element.getAttribute('href')
                        if (isNotNullOrUndefined(href)) {
                            const isExternalLink = isExternalUrl(href)
                            const content = element.textContent ?? 'View Content'

                            if (forceExternalLinksIntoIFrame && isExternalLink) {
                                return (
                                    <ExternalContentPreviewLink
                                        url={href}
                                        key={href}>
                                        {content}
                                    </ExternalContentPreviewLink>
                                )
                            }

                            if (!isExternalLink) {
                                return (
                                    <Link
                                        onClick={handleInternalLinkClick}
                                        to={href}
                                        key={href}>
                                        {content}
                                    </Link>
                                )
                            }

                            return (
                                <a
                                    key={href}
                                    href={href}
                                    target='_blank'
                                    rel='noreferrer'>
                                    {content}
                                </a>
                            )
                        }

                        return <>{element.textContent}</>
                    }

                    // Generic handling for other elements
                    return createElement(tagName, {}, element.textContent)
                }

                // Handle text nodes
                if (node.nodeType === Node.TEXT_NODE) {
                    return node.textContent
                }

                // Fallback for unsupported node types
                return null
            }

            // Map through child nodes and preprocess them
            return [...container.childNodes].map((childNode) => preprocessHtml(childNode))
        },
        [forceExternalLinksIntoIFrame, sanitizeOptions]
    )

    const sanitizedContent = useMemo(() => sanitizeInnerHtml(innerHTML), [sanitizeInnerHtml, innerHTML])

    return <Component {...rest}>{sanitizedContent}</Component>
}

export default HtmlRenderer
