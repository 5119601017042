import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './drawermenuitem.scss'

interface IDrawerMenuItemProperties {
    handleClick?: () => void
    icon?: ReactNode
    label?: string
    link?: string
    hidden?: boolean
}

const DrawerMenuItem: FC<IDrawerMenuItemProperties> = ({ handleClick, icon, label, link, hidden = false }) => {
    const isExternalLink = link?.startsWith('/') === false

    const renderChildContent = (
        <>
            <span className='c-settings--item-icon'>{icon}</span>
            <span className='c-settings--item-label'>{label}</span>
        </>
    )

    if (hidden) {
        return null
    }

    if (isExternalLink) {
        return (
            <a
                href={link}
                className='c-settings--item'
                target='_blank'
                rel='noopener noreferrer'>
                {renderChildContent}
            </a>
        )
    }

    if (isNotNullOrUndefined(link)) {
        return (
            <Link
                to={link}
                className='c-settings--item'
                onClick={handleClick}>
                {renderChildContent}
            </Link>
        )
    }

    return (
        <div
            className='c-settings--item'
            onClick={handleClick}>
            {renderChildContent}
        </div>
    )
}

export default DrawerMenuItem
