import { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import Button from 'src/components/01_atoms/Button/Button'
import LoyaltyCardGraphic from 'src/components/01_atoms/LoyaltyCardGraphic/LoyaltyCardGraphic'
import Modal from 'src/components/01_atoms/Modal/Modal'
import LoyaltyRegistration from 'src/components/03_organisms/LoyaltyRegistration/LoyaltyRegistration'
import { LoyaltyContext } from 'src/context/LoyaltyContextProvider'
import { useScreenHeight } from 'src/hooks/useScreenHeight'
import { closeModal } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'

import './loyaltyregistrationmodal.scss'

const LoyaltyRegistrationModal: FC<IModalDataProperties> = () => {
    const screenHeight = useScreenHeight()
    const { invalidateLoyaltyQuery, loyaltyProgramConfig, loyaltyContact } = useContext(LoyaltyContext)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleClose = useCallback(() => {
        dispatch(closeModal())
    }, [dispatch])

    const handleSuccessfulRegistration = useCallback(async () => {
        await invalidateLoyaltyQuery()
        handleClose()
    }, [handleClose, invalidateLoyaltyQuery])

    return (
        <div style={{ maxHeight: `${screenHeight ?? 0.9}px` }}>
            <Modal
                containerClassName='loyaltyregistration-container'
                contentClassName='loyaltyregistration-content'>
                <div className='inner'>
                    <div className='loyaltyregistration-card-container'>
                        <div className='loyaltyregistration-card'>
                            <LoyaltyCardGraphic
                                pointsLabel={loyaltyProgramConfig?.custom_credit_name}
                                points={
                                    isNotNullOrUndefined(loyaltyContact?.loyalty_balance)
                                        ? Number.parseInt(loyaltyContact?.loyalty_balance ?? '0', 10)
                                        : undefined
                                }
                            />
                        </div>
                        <div className='c-close-modal-header'>
                            <button
                                aria-label={t('general.button.close.label')}
                                onClick={handleClose}
                                className='c-button-close-modal-small'>
                                <FaTimes />
                            </button>
                        </div>
                    </div>
                    {isNotNullOrUndefined(loyaltyContact) ? (
                        <div className='loyaltyregistration-intro'>
                            <h1 className='u-text-center'>{t('loyalty.modal.registration.welcomeback.title')}</h1>
                            <p>{t('loyalty.modal.registration.welcomeback.body', { email: loyaltyContact.email })}</p>
                            <div className='loyaltyregistration-cta'>
                                <Button
                                    label='Get started'
                                    variant='dark'
                                    onClick={handleClose}
                                />
                            </div>
                        </div>
                    ) : (
                        <LoyaltyRegistration onSuccessfulRegistration={handleSuccessfulRegistration} />
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default LoyaltyRegistrationModal
