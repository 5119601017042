import { useContext } from 'react'
import classNames from 'classnames'

import { KioskContext } from '../../../context/KioskContextProvider'
import HtmlRenderer from '../../01_atoms/HtmlRenderer/HtmlRenderer.tsx'

import InputFeedback from './InputFeedback'

function Checkbox({
    field,
    form: { errors, touched },
    id,
    label,
    overrideLabel,
    className,
    disabled = false,
    ...props
}) {
    const { kioskMode } = useContext(KioskContext)

    const classes = classNames(
        'c-checkbox--default',
        {
            'has-error': !!errors[field.name] && touched[field.name],
            'u-disabled': disabled,
        },
        className
    )

    return (
        <div className={classes}>
            <input
                id={id}
                type='checkbox'
                className={classNames('checkbox')}
                disabled={disabled}
                {...field}
                {...props}
            />

            {overrideLabel ? (
                <label
                    htmlFor={id}
                    style={{ paddingRight: 24 }}>
                    {overrideLabel}
                </label>
            ) : (
                <HtmlRenderer
                    as='label'
                    forceExternalLinksIntoIFrame={kioskMode}
                    sanitizeOptions={{ ALLOWED_ATTR: ['target', 'rel', 'href'] }}
                    innerHTML={label}
                    htmlFor={id}
                    style={{ paddingRight: 24 }}
                />
            )}
            {touched[field.name] && <InputFeedback error={errors[field.name]} />}
        </div>
    )
}

export default Checkbox
