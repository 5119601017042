import { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    BiCookie,
    BiFile,
    BiHeart,
    BiHelpCircle,
    BiLogOutCircle,
    BiReceipt,
    BiSpreadsheet,
    BiUserCircle,
    BiWallet,
} from 'react-icons/bi'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { captureException, withScope } from '@sentry/react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import DrawerMenuItem from 'src/components/02_molecules/DrawerMenuItem/DrawerMenuItem'
import LanguageSelector from 'src/components/languageselector/LanguageSelector'
import Spinner from 'src/components/spinners/Spinner'
import { menuAnimation } from 'src/constants/Animations'
import { BrandContext } from 'src/context/BrandContextProvider'
import { DrawerMenuContext } from 'src/context/DrawerMenuContextProvider'
import { KioskContext } from 'src/context/KioskContextProvider'
import { UserContext } from 'src/context/UserAndTokenContextProvider'
import usePendingOrder from 'src/hooks/usePendingOrder'
import {
    closeAllModals,
    openCookiesModal,
    openModal,
    removeAllLoyalty,
    resetBasket,
    resetCashlessCard,
    updateTokens,
} from 'src/redux/actions/actionBuilders'
import billySDK from 'src/sdk/sdk'
import ModalType from 'src/structures/Enums/ModalType'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import UserType from 'src/structures/Enums/UserType'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './drawermenu.scss'

const DrawerMenu: FC<Record<string, never>> = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { user, updateUser } = useContext(UserContext) as any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { drawerMenuOpen, setDrawerMenuOpen } = useContext(DrawerMenuContext) as any
    const [isLoading, setIsLoading] = useState(false)
    const { brand, isBees, isBilly, isQbh, isTaDa } = useContext(BrandContext)
    const { kioskMode } = useContext(KioskContext)
    const { cancelAndRemovePendingOrder } = usePendingOrder()

    const userOfTypeUser = user?.type === UserType.USER

    const onLogoutClick = async () => {
        setIsLoading(true)
        await cancelAndRemovePendingOrder()
        billySDK
            .logout()
            .then(async () => {
                dispatch(
                    updateTokens({
                        access_token: undefined,
                    })
                )
                dispatch(removeAllLoyalty())
                dispatch(resetBasket())
                dispatch(resetCashlessCard())
                dispatch(closeAllModals())
                updateUser()
            })
            .catch(() => {
                withScope((scope) => {
                    scope.setUser({ id: user.id })
                    captureException(new Error('BillySDK.logout error'))
                })
            })
            .finally(() => {
                setIsLoading(false)
                setDrawerMenuOpen(false)
            })
    }

    const onCookiePrompt = useCallback(() => {
        setDrawerMenuOpen(false)
        if (isBees || isTaDa) {
            // eslint-disable-next-line prettier/prettier , @typescript-eslint/no-explicit-any
            window.OneTrust?.ToggleInfoDisplay()
        } else {
            dispatch(openCookiesModal())
        }
    }, [setDrawerMenuOpen, openCookiesModal])

    const onOpenAuthModal = useCallback(() => {
        setDrawerMenuOpen(false)
        dispatch(openModal({ type: ModalType.AUTHENTICATION_MODAL }))
    }, [dispatch])

    if (isLoading) {
        return (
            <AnimatePresence
                initial
                mode='wait'>
                {drawerMenuOpen === true ? (
                    <motion.div
                        className='c-settings'
                        variants={menuAnimation}
                        initial='hidden'
                        animate='visible'
                        exit='exit'>
                        <div style={{ height: '50vh' }}>
                            <Spinner />
                        </div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        )
    }

    if (kioskMode) {
        return (
            <AnimatePresence>
                {drawerMenuOpen === true ? (
                    <motion.div
                        className={classNames('c-settings', { 'c-settings-kiosk': kioskMode })}
                        variants={menuAnimation}
                        initial='hidden'
                        animate='visible'
                        exit='exit'>
                        <div className='c-settings-scroller'>
                            <LanguageSelector
                                onLanguageChange={() => {
                                    setDrawerMenuOpen(!isNotNullOrUndefined(drawerMenuOpen))
                                }}
                            />
                        </div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        )
    }

    return (
        <AnimatePresence>
            {drawerMenuOpen === true ? (
                <>
                    <motion.div
                        key='overlayer_desktop'
                        onClick={() => {
                            setDrawerMenuOpen(false)
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='c-settings-overlayer-desktop'
                    />
                    <motion.div
                        key='setting'
                        className='c-settings'
                        variants={menuAnimation}
                        initial='hidden'
                        animate='visible'
                        exit='exit'>
                        <div className='c-settings-scroller'>
                            {isNotNullOrUndefined(user) ? (
                                <>
                                    <div className='c-settings--header c-settings--header--loggedin'>
                                        {!userOfTypeUser ? (
                                            <div className='c-settings--header--user'>
                                                <div className='c-settings--header-user-picture'>
                                                    <img
                                                        src={brand.theme.logo.small}
                                                        alt={brand.brandName}
                                                    />
                                                </div>
                                                <div className='c-settings--header-user-data'>
                                                    <p>{`${user.firstname}`}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <Link
                                                className='c-settings--header-user-link'
                                                to={RoutePath.PROFILE}
                                                state={{
                                                    from: location.pathname,
                                                }}>
                                                <div className='c-settings--header--user'>
                                                    <div className='c-settings--header-user-picture'>
                                                        <img
                                                            src={brand.theme.logo.small}
                                                            alt={brand.brandName}
                                                        />
                                                    </div>
                                                    <div className='c-settings--header-user-data'>
                                                        <p>{`${user.firstname} ${user.name}`}</p>
                                                        <p className='user-email'>{user.email}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </div>

                                    <div className='c-settings--group'>
                                        <DrawerMenuItem
                                            label={t('settings.item.my_orders')}
                                            icon={<BiReceipt />}
                                            link={RoutePath.ORDERS_OVERVIEW}
                                        />

                                        {userOfTypeUser ? (
                                            <>
                                                <DrawerMenuItem
                                                    label={t('settings.item.my_profile')}
                                                    icon={<BiUserCircle />}
                                                    link={RoutePath.PROFILE}
                                                />

                                                <DrawerMenuItem
                                                    label={t('settings.item.my_wallet')}
                                                    icon={<BiWallet />}
                                                    link={RoutePath.CREDITS}
                                                    hidden={!isBilly}
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <div className='c-settings--group'>
                                    <DrawerMenuItem
                                        label={t(
                                            isBilly
                                                ? 'settings.item.billy_account'
                                                : `${brand.identifier}.settings.item.billy_account`
                                        )}
                                        handleClick={onOpenAuthModal}
                                        icon={<BiUserCircle />}
                                    />
                                </div>
                            )}

                            {isBees || isBilly || isTaDa ? (
                                <div className='c-settings--group'>
                                    <DrawerMenuItem
                                        link='https://support.orderbilly.com/'
                                        label={t('settings.item.help')}
                                        icon={<BiHelpCircle />}
                                        hidden={!isBilly}
                                    />

                                    <DrawerMenuItem
                                        hidden={!isBees && !isBilly && !isTaDa}
                                        handleClick={onCookiePrompt}
                                        label={t('settings.item.cookies')}
                                        icon={<BiCookie />}
                                    />
                                </div>
                            ) : null}

                            <div className='c-settings--group'>
                                <DrawerMenuItem
                                    link={RoutePath.LEGAL_TERMS}
                                    label={t('settings.item.terms_and_conditions')}
                                    icon={<BiFile />}
                                    hidden={isQbh}
                                />
                                <DrawerMenuItem
                                    link={
                                        isQbh
                                            ? 'https://privacy.dpgmedia.be/nl/document/privacy-policy'
                                            : RoutePath.LEGAL_PRIVACY
                                    }
                                    label={t('settings.item.privacy_policy')}
                                    icon={<BiSpreadsheet />}
                                />
                                <DrawerMenuItem
                                    link='https://meet.orderbilly.com/'
                                    label={t('settings.item.billy_in_your_venue')}
                                    icon={<BiHeart />}
                                    hidden={!isBilly}
                                />
                            </div>

                            <div className='c-settings--group'>
                                <LanguageSelector
                                    onLanguageChange={() => {
                                        setDrawerMenuOpen(!isNotNullOrUndefined(drawerMenuOpen))
                                    }}
                                />
                            </div>

                            {isNotNullOrUndefined(user) ? (
                                <div className='c-settings--group'>
                                    <DrawerMenuItem
                                        label={t('settings.item.logout')}
                                        icon={<BiLogOutCircle />}
                                        handleClick={async () => onLogoutClick()}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </motion.div>
                </>
            ) : null}
        </AnimatePresence>
    )
}

export default DrawerMenu
