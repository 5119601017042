import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Button from '../../../components/01_atoms/Button/Button.tsx'
import { slideInBottom } from '../../../constants/Animations.ts'
import { useScreenHeight } from '../../../hooks/useScreenHeight.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'

function InactivityModal({ data }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const screenHeight = useScreenHeight()

    const [remainingTime, setRemainingTime] = useState(data.timeLeft)
    const onStillHere = useCallback(() => {
        data.onStillHere?.()
        dispatch(closeModal())
    })

    const onReset = useCallback(() => {
        data.onReset?.()
    })

    useEffect(() => {
        const interval = setInterval(() => {
            if (remainingTime > 0) setRemainingTime(parseInt(remainingTime, 10) - 1000)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    return (
        <motion.div
            layout='position'
            style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--inactivity'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <motion.div className='c-modal--item-content'>
                        <div className='inner'>
                            <h3 className='c-modal--item-name'>{t('modal.inactivity.title')}</h3>
                            <p className='c-modal--item-text'>
                                {t('modal.inactivity.body', { remainingTime: remainingTime / 1000 })}
                            </p>
                        </div>
                        <div className='controls'>
                            <div>
                                <Button
                                    initial={{
                                        backgroundPosition: '100% 100%',
                                    }}
                                    animate={{
                                        backgroundPosition: '0% 100%',
                                        color: '#FFF',
                                    }}
                                    transition={{
                                        duration: data.timeLeft / 1000,
                                        bounce: 0,
                                    }}
                                    className='control-button cancel-order'
                                    variant='ghost'
                                    onClick={onReset}
                                    label={t('modal.inactivity.cta.cancel')}
                                />
                            </div>
                            <div>
                                <Button
                                    className='control-button'
                                    variant='dark'
                                    onClick={onStillHere}
                                    label={t('modal.inactivity.cta.still_here')}
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default InactivityModal
