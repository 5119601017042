import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import Input from '../../../components/forms/elements/Input'
import { slideInBottom } from '../../../constants/Animations.ts'
import { useScreenHeight } from '../../../hooks/useScreenHeight.ts'
import { closeModal } from '../../../redux/actions/actionBuilders'

import '../../../components/forms/elements/formelements.scss'
import './modal.scss'

function CustomTippingModal({ data = {} }) {
    const { t } = useTranslation()
    const screenHeight = useScreenHeight()
    const dispatch = useDispatch()

    const handleOnConfirm = useCallback(
        (values) => {
            const parsedValue = parseFloat(values.customTip.replace(',', '.')).toFixed(2)
            if (data.onConfirm) data.onConfirm(parseFloat(parsedValue) * 100)
            window.scrollTo(0, 0)
            dispatch(closeModal())
        },
        [dispatch, data]
    )

    const handleOnReject = useCallback(() => {
        if (data.onReject) data.onReject()
        window.scrollTo(0, 0)
        dispatch(closeModal())
    }, [dispatch, data])

    return (
        <div style={{ maxHeight: `${parseInt(screenHeight * 0.9, 10)}px` }}>
            <motion.div
                className='c-modal--item c-modal--cashless'
                variants={slideInBottom}
                initial='hidden'
                animate='visible'
                exit='exit'>
                <div className='c-fake-modal-backdrop'>
                    <div className='c-close-modal-header'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={handleOnReject}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>
                    <div className='c-modal--item-content'>
                        <Formik
                            initialValues={{
                                customTip: '',
                            }}
                            validationSchema={Yup.object().shape({
                                customTip: Yup.string().required(t('validation.email.required')),
                            })}
                            onSubmit={handleOnConfirm}>
                            {({ setFieldValue, handleBlur, isSubmitting }) => (
                                <Form style={{ margin: 0, padding: '50px 20px 30px' }}>
                                    <Field
                                        component={Input}
                                        name='customTip'
                                        id='customTip'
                                        type='text'
                                        maxLength='6'
                                        inputMode='decimal'
                                        label={t('input.customTip.label')}
                                        placeHolder={t('input.customTip.placeholder')}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            const { value } = e.target
                                            setFieldValue('customTip', value.replace(/[^\d,.]+/g, ''))
                                        }}
                                        disabled={isSubmitting}
                                        onBlur={(e) => {
                                            window.scrollTo(0, 0)
                                            handleBlur(e)
                                        }}
                                    />
                                    <div className='c-modal--confirm-buttons'>
                                        <button
                                            disabled={isSubmitting}
                                            type='button'
                                            className='c-button c-button-dark'
                                            onClick={handleOnReject}>
                                            {t('loyalty.modal.cancel', 'Cancel')}
                                        </button>
                                        <button
                                            disabled={isSubmitting}
                                            type='submit'
                                            className='c-button c-button-dark'>
                                            {t('loyalty.modal.submit', 'Submit')}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default CustomTippingModal
