import { CSSProperties, FC, useContext } from 'react'
import classNames from 'classnames'

import { ThemeContext } from '../../context/ThemeContextProvider'
import ImpulseSpinner from '../01_atoms/ImpulseSpinner/ImpulseSpinner'

import './spinner.scss'

export interface ISpinnerProperties {
    staticPosition?: boolean
    style?: CSSProperties
    color?: string
}

const Spinner: FC<ISpinnerProperties> = ({ staticPosition = false, style, color = null }) => {
    const { theme } = useContext(ThemeContext)

    const spinnerColor = color ?? `#${theme.color}`

    return (
        <div
            style={style}
            className={classNames('c-spinner', staticPosition && 'c-spinner--static')}>
            <ImpulseSpinner
                size={50}
                frontColor={spinnerColor}
                backColor={spinnerColor}
                loading
            />
        </div>
    )
}

export default Spinner
