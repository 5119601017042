import { matchPath } from 'react-router-dom'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import RoutePath from '../structures/Enums/RoutePath.enum'

// Define the structure of the path atlas
type PathAtlas = Record<string, Array<string>>

// Path atlas definition
const pathAtlas: PathAtlas = {
    event: [RoutePath.EVENT],
    eventGroup: [RoutePath.EVENT_GROUP],
    eventGroupEvent: [RoutePath.EVENT_GROUP_EVENT],
    venue: [RoutePath.VENUE],
    place: [RoutePath.PLACE],
    categoryGroup: [`${RoutePath.EVENT}/g/:categoryGroupId`, `${RoutePath.EVENT_GROUP_EVENT}/g/:categoryGroupId`],
    category: [
        `${RoutePath.EVENT}/c/:categoryId`,
        `${RoutePath.EVENT}/g/:categoryGroupId/c/:categoryId`,
        `${RoutePath.EVENT_GROUP_EVENT}/c/:categoryId`,
        `${RoutePath.EVENT_GROUP_EVENT}/g/:categoryGroupId/c/:categoryId`,
    ],
    purchase: [
        RoutePath.CREDIT_PURCHASES_OVERVIEW,
        RoutePath.CREDIT_PURCHASES_DETAIL,
        RoutePath.STARNET_TOPUP_OVERVIEW,
        RoutePath.STARNET_TOPUP_DETAIL,
    ],
}

// Merge all paths into a flat array
const pathAtlasMerged: Array<string> = Object.values(pathAtlas).flat()

// Create a reverse lookup map for quick type matching
const pathTypeMap: Map<string, string> = new Map(
    Object.entries(pathAtlas).flatMap(([type, paths]) => paths.map((path) => [path, type]))
)

// Check if any paths match the given location path.
export const matchPaths = (pathArray: Array<string>, locationPath: string): boolean =>
    pathArray.some((path) => matchPath({ path }, locationPath) !== null)

// Find the matching path type for a given location path.
export const matchPathsByType = (locationPath: string): string | undefined => {
    const matchedPath = pathAtlasMerged.find((path) => matchPath({ path }, locationPath) !== null)
    return isNotNullOrUndefined(matchedPath) ? pathTypeMap.get(matchedPath) : undefined
}
