import { useCallback, useContext, useRef, useState } from 'react'
import { BiVolume, BiVolumeMute } from 'react-icons/bi'
import { MdClose, MdOutlineReplay } from 'react-icons/md'
import ReactPlayer from 'react-player/lazy'
import { useDispatch } from 'react-redux'

import { ThemeContext } from '../../context/ThemeContextProvider'
import { closeModal } from '../../redux/actions/actionBuilders'
import ProgressBar from '../ProgressBar/ProgressBar'
import Spinner from '../spinners/Spinner.tsx'
import StoryControl from '../StoryControl/StoryControl'

import './storyvideo.scss'

function StoryVideo({ url = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4' }) {
    const storyRef = useRef()
    const dispatch = useDispatch()
    const [isMuted, setisMuted] = useState(true)
    const [progress, setProgress] = useState(0)
    const { theme } = useContext(ThemeContext)

    const onReplayStory = useCallback(() => {
        storyRef?.current.seekTo(0)
    }, [])

    const onToggleMuteStory = useCallback(() => {
        setisMuted((prevMutedState) => !prevMutedState)
    }, [])

    const onCloseStory = useCallback(() => {
        dispatch(closeModal())
    }, [])

    const handleProgress = useCallback((progress) => {
        setProgress(progress.played)
    }, [])

    return (
        <div className='video-story-wrapper'>
            <div className='video-story'>
                <Spinner />
                <ReactPlayer
                    ref={storyRef}
                    className='video-story-player'
                    width='100%'
                    height='100%'
                    muted={isMuted}
                    playsinline
                    playing
                    url={url}
                    onEnded={onCloseStory}
                    onProgress={handleProgress}
                />
                <div className='video-story-controls'>
                    <div className='video-controls-top'>
                        <div>
                            <div className='q-logo'>{theme?.logo ? <img src={theme.logo} /> : null}</div>
                        </div>
                        <div
                            className='video-story-title'
                            style={{ marginLeft: '10px', marginRight: '10px', width: '100%' }}>
                            <ProgressBar
                                transitionDuration='1s'
                                transitionTimingFunction='linear'
                                progress={Math.round(progress * 100)}
                            />
                        </div>
                        <div>
                            <StoryControl
                                onClick={onCloseStory}
                                icon={<MdClose />}
                            />
                        </div>
                    </div>
                    <div className='video-controls-bottom'>
                        <StoryControl
                            onClick={onReplayStory}
                            icon={<MdOutlineReplay />}
                        />
                        <StoryControl
                            onClick={onToggleMuteStory}
                            icon={isMuted ? <BiVolumeMute /> : <BiVolume />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoryVideo
