import { FC, ReactNode, useContext } from 'react'
import classNames from 'classnames'
import { KioskContext } from 'src/context/KioskContextProvider'

import './rubberband.scss'

interface IRubberBandProperties {
    variant?: 'large' | 'small'
    children: ReactNode
}

const RubberBand: FC<IRubberBandProperties> = ({ variant = 'large', children }) => {
    const { kioskMode } = useContext(KioskContext)

    if (kioskMode) {
        return children
    }

    return (
        <section
            className={classNames('c-rubberband', {
                small: variant === 'small',
            })}>
            {children}
        </section>
    )
}

export default RubberBand
