import { FC, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import Modal from 'src/components/01_atoms/Modal/Modal'
import SmallDivider from 'src/components/SmallDivider/SmallDivider'
import Spinner from 'src/components/spinners/Spinner'
import { EventContext } from 'src/context/EventContextProvider'
import { UserContext } from 'src/context/UserAndTokenContextProvider'
import { useScreenHeight } from 'src/hooks/useScreenHeight'
import { closeModal, openModal, updateTokens } from 'src/redux/actions/actionBuilders'
import CheckoutMethod from 'src/structures/Enums/CheckoutMethod.enum'
import ModalType from 'src/structures/Enums/ModalType'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'

import BillySDK from '../../../../sdk/sdk'

import './authenticationmodal.scss'

const AuthenticationModal: FC<IModalDataProperties> = ({ data }) => {
    const { t } = useTranslation()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { user } = useContext(UserContext) as any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { event } = useContext(EventContext) as any

    const location = useLocation()

    const screenHeight = useScreenHeight()
    const dispatch = useDispatch()

    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)

    const guestAccountsDisabled = useMemo(() => {
        if (
            (isNotNullOrUndefined(data?.guestDisabled) && data?.guestDisabled === true) ||
            (isNotNullOrUndefined(event) && event.allows_guest_users === false)
        ) {
            return true
        }
        return false
    }, [data, event?.allows_guest_users])

    const redirectPath = useMemo(
        () =>
            isNotNullOrUndefined(location?.pathname)
                ? `${location.pathname}${isNotNullOrUndefined(data?.searchParams) ? data?.searchParams : ''}`
                : RoutePath.HOMEPAGE,
        [location?.pathname, data?.searchParams]
    )

    const handleAuthError = useCallback(() => {
        dispatch(
            openModal({
                type: ModalType.ERROR_MODAL,
            })
        )
    }, [])

    const handleClose = useCallback(() => {
        dispatch(closeModal())
        data?.onDenyAuth?.()
    }, [dispatch, data])

    const onSocialClick = useCallback(() => {
        setIsLoggingIn(true)
        handleClose()
    }, [handleClose])

    const onGuestClick = useCallback(() => {
        setIsLoggingIn(true)

        BillySDK.guestLogin()
            .then((response) => {
                dispatch(
                    updateTokens({
                        access_token: response.token,
                    })
                )
                data?.onAuthenticated?.()
                setIsLoggingIn(false)
                handleClose()
            })
            .catch(() => {
                data?.onDenyAuth?.()
                setIsLoggingIn(false)
                handleClose()
                handleAuthError()
            })
    }, [data])

    // Stupid Roomservice hack to instaltly login guestusers
    useLayoutEffect(() => {
        if (event?.checkout_method.id === CheckoutMethod.ROOMSERVICE && event?.allows_guest_users === true) {
            onGuestClick()
        }
    }, [event, onGuestClick])
    // hide the modal if roomservice
    if (event?.checkout_method.id === CheckoutMethod.ROOMSERVICE && event?.allows_guest_users === true) {
        return null
    }

    return (
        <div style={{ maxHeight: `${screenHeight ?? 1 * 0.9}px` }}>
            <Modal
                containerClassName='authenticationmodal-container'
                contentClassName='authenticationmodal-content'>
                <div className='inner'>
                    <div className='authenticationmodal-header'>
                        <h2>{t('loginfirst.create_account.title')}</h2>
                        <div className='c-close-modal-header'>
                            <button
                                disabled={isLoggingIn}
                                aria-label={t('general.button.close.label')}
                                onClick={handleClose}
                                className='c-button-close-modal-small'>
                                <FaTimes />
                            </button>
                        </div>
                    </div>
                    {!isNotNullOrUndefined(user) ? (
                        <div className='authenticationmodal-options-container'>
                            <a
                                className={classNames('c-button c-button-apple', { disabled: isLoggingIn })}
                                onClick={onSocialClick}
                                href={`${
                                    BillySDK.baseURI
                                }/api/user/login/apple/redirect?from=${window.encodeURIComponent(redirectPath)}`}>
                                {t('button.continue_with_apple.cta')}
                            </a>

                            <a
                                className={classNames('c-button c-button-google', { disabled: isLoggingIn })}
                                onClick={onSocialClick}
                                href={`${
                                    BillySDK.baseURI
                                }/api/user/login/google/redirect?from=${window.encodeURIComponent(redirectPath)}`}>
                                {t('button.continue_with_google.cta')}
                            </a>

                            <a
                                className={classNames('c-button c-button-facebook', { disabled: isLoggingIn })}
                                onClick={onSocialClick}
                                href={`${
                                    BillySDK.baseURI
                                }/api/user/login/facebook/redirect?from=${window.encodeURIComponent(redirectPath)}`}>
                                {t('button.continue_with_facebook.cta')}
                            </a>

                            <Link
                                onClick={handleClose}
                                className={classNames('c-button', { disabled: isLoggingIn })}
                                to={
                                    !isLoggingIn
                                        ? `${RoutePath.REGISTER}?from=${window.encodeURIComponent(redirectPath)}`
                                        : '#'
                                }>
                                {t('button.continue_with_email.cta')}
                            </Link>

                            {guestAccountsDisabled ? null : (
                                <div style={{ marginTop: '15px' }}>
                                    <SmallDivider dividerText={t('common.word.or')} />

                                    <button
                                        disabled={isLoggingIn}
                                        className='c-button c-button-guest'
                                        onClick={onGuestClick}>
                                        {isLoggingIn ? (
                                            <Spinner
                                                staticPosition
                                                style={{ margin: 0, padding: 0 }}
                                            />
                                        ) : (
                                            t('button.create_guest_account.cta')
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Spinner staticPosition />
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default AuthenticationModal
