export const slideInBottom = {
    hidden: {
        position: 'relative' as const,
        y: 450,
    },
    visible: {
        position: 'relative' as const,
        y: 0,
        transition: {
            duration: 0.05,
            type: 'spring' as const,
            dampening: 1050,
            stiffness: 90,
            mass: 0.2,
        },
    },
    exit: {},
}

export const fadeIn = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.01,
            type: 'spring' as const,
            dampening: 1000,
            stiffness: 100,
            mass: 0.2,
        },
    },
    exit: {
        opacity: 0,
    },
}

export const fadeInUp = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.01,
            type: 'spring' as const,
            dampening: 1000,
            stiffness: 100,
            mass: 0.2,
        },
    },
    exit: {
        opacity: 0,
    },
}

export const fadeInDown = {
    hidden: {
        opacity: 0,
        y: -30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.01,
            type: 'spring' as const,
            dampening: 1000,
            stiffness: 100,
            mass: 0.2,
        },
    },
    exit: {
        opacity: 0,
    },
}

export const staggerFadeInUpContainer = {
    hidden: { opacity: 1 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.04,
        },
    },
}

export const staggerFadeInUpChild = {
    hidden: { opacity: 0, y: 30 },
    show: { opacity: 1, y: 0 },
}

export const menuAnimation = {
    hidden: {
        x: '105vw' as const,
    },
    visible: {
        x: 0,
        transition: {
            duration: 0.25,
            bounce: 0,
            type: 'spring' as const,
        },
    },
    exit: {
        x: '105vw' as const,
        transition: {
            duration: 0.25,
            bounce: 0,
            type: 'spring' as const,
        },
    },
}

export const popIn = {
    hidden: {
        scale: 0.95,
    },
    visible: {
        scale: 1,
        transition: {
            duration: 0.1,
            type: 'spring' as const,
            dampening: 25,
            stiffness: 250,
            mass: 1,
        },
    },
    exit: {
        scale: 1,
    },
}
