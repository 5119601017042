import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import Button from 'src/components/01_atoms/Button/Button'
import { ExpandableText } from 'src/components/01_atoms/ExpandableText/ExpandableText'
import Modal from 'src/components/01_atoms/Modal/Modal'
import ModalControls from 'src/components/01_atoms/ModalControls/ModalControls'
import ProductAllergens from 'src/components/01_atoms/ProductAllergens/ProductAllergens'
import ProductModalImage from 'src/components/01_atoms/ProductModalImage/ProductModalImage'
import ProductTags from 'src/components/01_atoms/ProductTags/ProductTags'
import Recommendations from 'src/components/CrossSell/Recommendations'
import ProductConfigurator from 'src/components/ProductConfigurator/ProductConfigurator'
import PromoRecommendations from 'src/components/PromoRecommendation/PromoRecommendations'
import QuantitySelector from 'src/components/QuantitySelector/QuantitySelector'
import useValidateAdditions, { ISelectedAdditions } from 'src/hooks/useValidateAdditions'
import { addToBasket, closeModal } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'
import IProduct from 'src/structures/Interfaces/IProduct'

import Additions from '../../Additions/Additions'

import './productmodal.scss'

interface IReccomendationSelection {
    amount: number
    product: IProduct
}

const ProductModal: FC<IModalDataProperties> = ({ data }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const product: IProduct = data?.product ?? null
    const promotions = data?.promotions ?? null
    const isOrderable = data?.isOrderable ?? true
    const isMenuOnly = data?.menuOnly === true
    const hasAdditions = data?.product?.additions?.length > 0
    const additionsConfigurator = data?.product?.has_stepped_additions ?? false

    const hasConfigurator =
        isNotNullOrUndefined(product.additions) &&
        !isMenuOnly &&
        isOrderable === true &&
        hasAdditions &&
        additionsConfigurator === true

    const [selectedRecommendations, setSelectedRecommendations] = useState<Array<IProduct & { amount: number }>>([])

    const [amount, setAmount] = useState<number>(data.amount)

    const { validateAdditions, totalAdditionsPrice, selectedAdditions, isValid } = useValidateAdditions(
        data.product?.additions
    )

    const onUpdateRecommendations = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        ({ product, amount }: IReccomendationSelection) => {
            if (!isNotNullOrUndefined(product)) return

            const existingItemIndex = selectedRecommendations.findIndex((item) => item.id === product.id)

            // Item should be removed
            if (amount === 0 && existingItemIndex !== -1) {
                const updatedItems = selectedRecommendations.filter((item) => item.id !== product.id)
                setSelectedRecommendations(updatedItems)
                return
            }

            // Amount of selection should be updated
            if (amount > 0 && existingItemIndex !== -1) {
                const updatedItems = [...selectedRecommendations]
                if (isNotNullOrUndefined(updatedItems?.[existingItemIndex]?.amount)) {
                    updatedItems[existingItemIndex].amount = amount
                    setSelectedRecommendations(updatedItems)
                }
                return
            }
            // Add the new recommendation to the selection Array
            setSelectedRecommendations((previousSelection: Array<IProduct & { amount: number }>) => [
                ...previousSelection,
                { ...product, amount: amount ?? 1 },
            ])
        },
        [selectedRecommendations]
    )

    const onUpdateAmount = useCallback((adjuster: number) => {
        setAmount((previousState) => Math.max(previousState + adjuster, 1))
    }, [])

    const onUpdateAdditions = useCallback(
        (values: ISelectedAdditions) => {
            validateAdditions(values)
        },
        [validateAdditions]
    )

    const handleClose = useCallback(() => {
        data?.onDeny?.()
        dispatch(closeModal())
    }, [dispatch, data])

    const handleConfirm = useCallback(() => {
        // Add product to basket
        dispatch(
            addToBasket({
                ...data.product,
                event_id: data.eventId,
                eventName: data.eventName,
                groupId: data.groupId,
                additions: { ...selectedAdditions },
                has_additions: Object.keys({ ...selectedAdditions }).length > 0,
                totalAdditionsPrice,
                amount,
                type: data.type ?? null,
                type_reference_id: data.type_reference_id ?? null,
            })
        )

        // Add all selected recommendations to basket
        for (const recommendation of selectedRecommendations) {
            dispatch(
                addToBasket({
                    ...recommendation,
                    event_id: isNotNullOrUndefined(recommendation?.event_id) ? recommendation.event_id : data.eventId,
                    eventName: data.eventName,
                    groupId: data.groupId,
                    is_recommendation: true,
                    parent_product_id: data.product.id,
                    type: 'recommendation',
                    type_reference_id: data.product.id,
                    has_additions: false,
                    totalAdditionsPrice: 0,
                    amount: amount * (recommendation.amount ?? 1),
                })
            )
        }

        data.onConfirm?.()
        dispatch(closeModal())
    }, [data, amount, totalAdditionsPrice, selectedAdditions, selectedRecommendations, addToBasket, dispatch])

    if (isNotNullOrUndefined(product)) {
        return (
            <Modal
                containerClassName='productmodal-container'
                contentClassName='productmodal-content'>
                <div className='inner'>
                    <div className='productmodal-close'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={handleClose}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>

                    <ProductModalImage
                        image={product.images?.medium ?? undefined}
                        imageSize={isNotNullOrUndefined(product.image_size) ? product.image_size : 'cover'}
                    />

                    <div className='productmodal-title'>
                        <h2 className='productmodal-title-product-name'>{product.name}</h2>
                    </div>

                    {isNotNullOrUndefined(product.description) && product.description.length > 0 ? (
                        <div className='productmodal-description'>
                            <ExpandableText
                                id={String(product.id)}
                                text={product.description}
                            />
                        </div>
                    ) : null}

                    <ProductAllergens allergens={product.allergens} />
                    <ProductTags tags={product.tags} />

                    {hasConfigurator ? (
                        <ProductConfigurator
                            requestStartConfigurator={undefined}
                            product={product}
                            selectedAdditions={selectedAdditions}
                            onAdditionsChange={onUpdateAdditions}
                        />
                    ) : null}

                    {isNotNullOrUndefined(product.additions) && hasAdditions && additionsConfigurator === false ? (
                        <Additions
                            isOrderable={isOrderable}
                            additions={product.additions ?? []}
                            onAdditionsChange={onUpdateAdditions}
                        />
                    ) : null}

                    {isOrderable === false || isMenuOnly ? null : (
                        <>
                            <Recommendations
                                recommendations={data.product.recommendations}
                                onRecommendationClick={onUpdateRecommendations}
                            />
                            <PromoRecommendations
                                product={product}
                                promotions={promotions}
                            />
                        </>
                    )}

                    <ModalControls
                        alert={
                            data.isOrderable === false
                                ? data.notOrderableReason ?? t('product.not_orderable.description')
                                : null
                        }>
                        {isOrderable === false || isMenuOnly || data.maxOrderableAmount === 0 ? (
                            <div className='productmodal-controls'>
                                {data.maxOrderableAmount > 0 ? null : (
                                    <p className='c-quantityselector-maxorderedmessage'>
                                        {t('product.maximum_in_basket_reached.label')}
                                    </p>
                                )}
                                <Button
                                    className='add-button'
                                    label={t('button.close.cta')}
                                    onClick={handleClose}
                                    variant='dark'
                                />
                            </div>
                        ) : (
                            <div className='productmodal-controls'>
                                <div className='c-quantityselector-wrapper'>
                                    <QuantitySelector
                                        type='small'
                                        amount={amount}
                                        range={{
                                            min: 1,
                                            max: data.maxOrderableAmount,
                                        }}
                                        onQuantityChange={onUpdateAmount}
                                    />
                                    {data.maxOrderableAmount <= 20 ? (
                                        <p className='c-quantityselector-maxorderablelabel'>
                                            {t('product.maximum_allowed_in_basket.label', {
                                                amount: data.maxOrderableAmount,
                                            })}
                                        </p>
                                    ) : null}
                                </div>

                                <Button
                                    className='add-button'
                                    label={t('button.add_to_basket.cta')}
                                    onClick={handleConfirm}
                                    disabled={!isValid}
                                    variant='dark'
                                />
                            </div>
                        )}
                    </ModalControls>
                </div>
            </Modal>
        )
    }

    return null
}

export default ProductModal
