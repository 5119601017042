import { CSSProperties, FC, ReactNode } from 'react'
import classNames from 'classnames'
import RubberBand from 'src/components/01_atoms/Rubberband/Rubberband'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import './container.scss'

interface IContainerProperties {
    id?: string
    className?: string
    style?: CSSProperties
    fullWidth?: boolean
    emptyView?: boolean
    rubberBand?: boolean
    smallRubberBand?: boolean
    animated?: boolean
    children?: ReactNode
}

const Container: FC<IContainerProperties> = ({
    id,
    className,
    style,
    fullWidth,
    emptyView,
    rubberBand,
    smallRubberBand,
    animated,
    children,
}) => {
    const containerClassName = classNames(
        'c-container',
        {
            'c-container--full-width': fullWidth,
            'c-container--empty-view': emptyView,
            'c-container--animated': animated,
        },
        className
    )

    if (isNotNullOrUndefined(rubberBand) && rubberBand) {
        return (
            <RubberBand variant={smallRubberBand === true ? 'small' : 'large'}>
                <div
                    id={id}
                    className={containerClassName}
                    style={style}>
                    {children}
                </div>
            </RubberBand>
        )
    }

    return (
        <div
            id={id}
            className={containerClassName}
            style={style}>
            {children}
        </div>
    )
}

export default Container
