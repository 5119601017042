import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence } from 'framer-motion'
import { useLongPress } from 'use-long-press'

import KioskIntro from '../components/03_organisms/KioskIntro/KioskIntro.tsx'
import useIdleMonitor from '../hooks/useIdleMonitor'
import useKioskUser from '../hooks/useKioskUser'
import usePendingOrder from '../hooks/usePendingOrder'
import { closeAllModals, openModal, resetBasket, setKioskToken, updateKioskMode } from '../redux/actions/actionBuilders'
import BillySDK from '../sdk/sdk'
import ModalType from '../structures/Enums/ModalType.ts'
import RoutePath from '../structures/Enums/RoutePath.enum.ts'

import { EventContext } from './EventContextProvider'
import { EventGroupContext } from './EventGroupContextProvider'

export const KioskContext = createContext({
    kioskMode: false,
    isKioskIntroScreenVisible: true,
    isIdleTimerEnabled: true,
    setIsIdleTimerEnabled: (isIdleTimerEnabled) => isIdleTimerEnabled,
    requestKioskReset: () => undefined,
    resetKiosk: () => undefined,
    setIsKioskIntroScreenVisible: (flag) => flag,
    isHandHeld: false,
})

function KioskContextProvider({ children }) {
    const kioskMatchPath = useMatch('/kiosk/:kioskId')
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const { cancelAndRemovePendingOrder } = usePendingOrder()
    const { event } = useContext(EventContext)
    const { eventGroup } = useContext(EventGroupContext)

    const { token, kioskMode, device } = useSelector((state) => state.kiosk)

    const { resetKioskUser } = useKioskUser(kioskMode)
    // intro screen display
    const [isKioskIntroScreenVisible, setIsKioskIntroScreenVisible] = useState(true)
    const [isIdleTimerEnabled, setIsIdleTimerEnabled] = useState(true)

    const onhideIntro = useCallback(() => {
        queryClient.invalidateQueries({ queryKey: ['eventGroup'] })
        queryClient.invalidateQueries({ queryKey: ['event'] })
        queryClient.invalidateQueries({ queryKey: ['order'] })
        queryClient.invalidateQueries({ queryKey: ['orderStatus'] })
        queryClient.invalidateQueries({ queryKey: ['products'] })
        setIsKioskIntroScreenVisible(false)
    }, [queryClient, setIsKioskIntroScreenVisible])

    useEffect(() => {
        if (device?.id) {
            const axiosInstance = BillySDK.getAxiosInstance()
            if (axiosInstance) {
                axiosInstance.defaults.headers.common = Object.assign(axiosInstance.defaults.headers.common, {
                    'x-device-id': device.id,
                })
            }

            i18n.changeLanguage(device.config.language ?? navigator.language ?? navigator.userLanguage ?? 'en')
        }
    }, [device, i18n, BillySDK?.getAxiosInstance])

    // if we visit the kiosk trigger route, initiate kiosk
    useEffect(() => {
        if (kioskMatchPath !== null) {
            dispatch(setKioskToken(kioskMatchPath.params.kioskId))
            dispatch(updateKioskMode(true))
        }
    }, [kioskMatchPath, dispatch])

    // handle interaction timeouts
    useIdleMonitor({
        disabled: kioskMode !== true ? true : isKioskIntroScreenVisible || !isIdleTimerEnabled,
        timeout: 60000, // inlcudes the promptBeforeIdle time below
        promptBeforeIdle: 10000,
        callBack: () => resetKiosk(),
    })

    // reset kiosk callback
    const resetKiosk = useCallback(async () => {
        setIsKioskIntroScreenVisible(true)

        window.localStorage.removeItem('kioskForm')
        window.localStorage.removeItem(`kioskForm-step`)

        dispatch(resetBasket())
        dispatch(closeAllModals())

        await cancelAndRemovePendingOrder()
        await resetKioskUser()

        await queryClient.removeQueries({ queryKey: ['loyaltyContact'] })
        await queryClient.removeQueries({ queryKey: ['loyaltyProgram'] })
        await queryClient.invalidateQueries({ queryKey: ['device'] })

        if (token) {
            navigate(`/kiosk/${token}`)
        } else if (eventGroup?.id) {
            navigate(`/eventgroups/${eventGroup?.id}`)
        } else if (event?.id) {
            navigate(`/events/${event?.id}`)
        } else {
            navigate(RoutePath.HOMEPAGE)
        }
    }, [dispatch, queryClient, navigate, token, eventGroup, event])

    // User wants to cancel kiosk visit and reset
    const requestKioskReset = useCallback(() => {
        dispatch(
            openModal({
                type: ModalType.CONFIRM_MODAL,
                data: {
                    title: t('modal.warning.empty_basket.title'),
                    message: t('modal.warning.empty_basket.body'),
                    onConfirm: () => resetKiosk(),
                },
            })
        )
    }, [resetKiosk])

    // refresh the koisk by pressing 10s in the left bottom corner
    const refreshPage = useCallback(() => {
        queryClient.invalidateQueries({ queryKey: ['eventGroup'] })
        queryClient.invalidateQueries({ queryKey: ['event'] })
        queryClient.invalidateQueries({ queryKey: ['products'] })
        resetKiosk().finally(() => window.location.reload())
    }, [resetKiosk])

    const longpressReset = useLongPress(refreshPage, {
        threshold: 5 * 1000,
    })

    if (kioskMode) {
        return (
            <KioskContext.Provider
                value={{
                    kioskMode,
                    isIdleTimerEnabled,
                    setIsIdleTimerEnabled,
                    requestKioskReset,
                    resetKiosk,
                    isKioskIntroScreenVisible,
                    setIsKioskIntroScreenVisible,
                    isHandHeld: !!device?.kioskUserAccessToken,
                }}>
                <button
                    {...longpressReset()}
                    style={{ position: 'fixed', bottom: 0, zIndex: 20000, width: 20, height: 20, opacity: 0 }}>
                    x
                </button>
                <AnimatePresence>
                    {isKioskIntroScreenVisible ? <KioskIntro onClick={onhideIntro} /> : null}
                </AnimatePresence>
                {children}
            </KioskContext.Provider>
        )
    }

    return children
}

export default KioskContextProvider
